import {
    ref,
    reactive,
    del,
    set,
    computed,
    onActivated,
    onMounted,
    watch,
    getCurrentInstance,
    defineExpose,
    onBeforeUnmount,
    nextTick,
} from "@vue/composition-api";

import VueForm, { fieldProps, vueUtils } from "@lljj/vue-json-schema-form";
// 圖片上傳組件
import TemplateUploadImgFile from "../components/templateUploadImgFile.vue";
// 影片上傳組件
import TemplateUploadVideoFile from "../components/templateUploadVideoFile.vue";

import SideBar from "@/views/templateSetting/components/SideBar.vue";
import store from "@/store";
import { isEmpty } from "@/service/anyService";

export default {
    name: "templateIndexNews",
    components: {
        TemplateUploadImgFile,
        TemplateUploadVideoFile,
        SideBar,
        VueForm,
    },
    props: {},
    emits: [],
    setup(props, { emit }) {
        const limitFileSize = ref(
            store.state.utilityStore.uiSwitchSettingDatas.upload_photo_size ??
                process.env.VUE_APP_UPLOAD_PHOTO_SIZE
        );

        const templateForm = ref({
            templateSelect: {
                style: "tmp1",
            },
        });

        const templateSchema = ref({
            type: "object",
            properties: {
                templateSelect: {
                    title: "樣板選擇",
                    type: "object",
                    properties: {
                        style: {
                            title: "當前選擇樣板",
                            type: "string",
                            enum: ["tmp1", "tmp2"],
                            enumNames: ["樣板1", "樣板2"],
                            "ui:width": "40%",
                        },
                    },
                },
            },
        });

        const photoSizes = ref({
            width: [1920],
            height: [300],
        });
        const datasForm = ref({
            dataList: [
                {
                    imgUpload: JSON.stringify({
                        imgUrl: "https://firebasestorage.googleapis.com/v0/b/timpicturespace.appspot.com/o/overflow-01.png?alt=media&token=7ed8d6b1-24f8-4cb9-8670-c3f3e911c1ad",
                        sizes: photoSizes.value,
                    }),
                    type: "image",
                    title: "first",
                },
                {
                    videoUpload: JSON.stringify({
                        videoUrl:
                            "https://videos.pexels.com/video-files/3209663/3209663-uhd_3840_2160_25fps.mp4",
                        sizes: limitFileSize.value,
                    }),
                    type: "video",
                    title: "second",
                },
            ],
        });

        const datasSchema = ref({
            type: "object",
            required: ["dataList"],
            properties: {
                dataList: {
                    title: "上傳最新消息圖片與描述",
                    type: "array",
                    minItems: 1,
                    maxItems: 3,
                    items: {
                        type: "object",
                        required: ["type", "title", "description", "url"],
                        properties: {
                            imgUpload: {
                                title: "背景圖片",
                                type: "string",
                                "ui:widget": TemplateUploadImgFile,
                                default: JSON.stringify({
                                    imgUrl: null,
                                    sizes: photoSizes.value,
                                }),
                            },
                            videoUpload: {
                                title: "背景影片",
                                type: "string",
                                "ui:widget": TemplateUploadVideoFile,
                                default: JSON.stringify({
                                    videoUrl: null,
                                    sizes: limitFileSize.value,
                                }),
                            },
                            type: {
                                title: "圖片/影片",
                                type: "string",
                                enum: ["image", "video"],
                                enumNames: ["圖片", "影片"],
                                "ui:width": "30%",
                                "err:required": "請選擇種類",
                            },
                            title: {
                                title: "標題",
                                type: "string",
                                "ui:width": "30%",
                                "err:required": "請輸入標題",
                                "ui:options": {
                                    // slots，需要使用render函数来实现
                                    // 配置 renderChildren ，返回 Vnode[] 其中slot即为slotName
                                    // render 函数参考：https://cn.vuejs.org/v2/guide/render-function.html#%E6%B7%B1%E5%85%A5%E6%95%B0%E6%8D%AE%E5%AF%B9%E8%B1%A1
                                    renderScopedSlots(h) {
                                        return {
                                            append: (props) =>
                                                h("span", ".com"),
                                        };
                                    },
                                },
                            },
                            description: {
                                title: "請輸入描述",
                                type: "string",
                                "err:required": "請輸入描述",
                            },
                            url: {
                                title: "輸入網址",
                                type: "string",
                                "err:required": "請輸入網址",
                            },
                            startDate: {
                                title: "開始日期",
                                type: "string",
                                format: "date-time",
                                "ui:width": "30%",
                                "ui:options": {
                                    placeholder: "請選擇開始日期",
                                },
                            },
                            endDate: {
                                title: "結束日期",
                                type: "string",
                                format: "date-time",
                                "ui:width": "30%",
                                "ui:options": {
                                    placeholder: "請選擇結束日期",
                                },
                            },
                        },
                    },
                },
            },
        });
        const dataUiSchema = ref({
            dataList: {
                items: {
                    imgUpload: {
                        "ui:hidden": `{{parentFormData.type === "video"}}`,
                    },
                    videoUpload: {
                        "ui:hidden": `{{parentFormData.type === "image"}}`,
                    },
                },
            },
        });

        const customRule = ref(({ field, value, rootFormData, callback }) => {
            const rules = [
                {
                    rule: /dataList\.\d+\.url/,
                    validator(value, rootFormData) {
                        const regex =
                            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
                        if (!regex.test(value)) {
                            return "請輸入正確網址";
                        }
                    },
                },
                {
                    rule: /dataList\.\d+\.imgUpload/,
                    validator(value, rootFormData) {
                        const jsonDataParse = JSON.parse(value);
                        if (jsonDataParse.imgUrl === null) {
                            return "請上傳背景圖片";
                        }
                    },
                },
                {
                    rule: /dataList\.\d+\.videoUpload/,
                    validator(value, rootFormData) {
                        const jsonDataParse = JSON.parse(value);
                        if (jsonDataParse.videoUrl === null) {
                            return "請上傳背景影片";
                        }
                    },
                },
            ];

            for (const ruleItem of rules) {
                // String | Regx
                if (
                    (String(ruleItem.rule) === ruleItem.rule &&
                        ruleItem.rule === field) ||
                    (Object.prototype.toString.call(ruleItem.rule) ===
                        "[object RegExp]" &&
                        ruleItem.rule.test(field))
                ) {
                    const error = ruleItem.validator(value, rootFormData);
                    // 停止檢查
                    if (error) return callback(error);
                }
            }
            return callback();
        });
        function addClass() {}
        async function onSubmit(getFn, getForm) {
            try {
                await getFn().validate();
                console.log("form =>", getForm);
            } catch (err) {
                console.log("validate err =>", err);
            }
        }
        return {
            addClass,
            templateForm,
            templateSchema,
            datasForm,
            datasSchema,
            dataUiSchema,
            customRule,
            onSubmit,
        };
    },
    render() {
        const scopedSlots = {
            default: (data) => (
                <div>
                    <pre
                        style={{
                            backgroundColor: "#eee",
                            overflowX: "scroll",
                            width: "800px",
                        }}
                    >
                        {JSON.stringify(data.formData, null, 4)}
                    </pre>
                    <p>
                        <el-button
                            type="primary"
                            onClick={() =>
                                this.onSubmit(data.formRefFn, data.formData)
                            }
                        >
                            Submit
                        </el-button>
                    </p>
                </div>
            ),
        };
        return (
            <div class="flex mx-5 my-5">
                <SideBar
                    btnText={"+ 新增類別"}
                    clickBtn={() => this.addClass()}
                />
                <div class="pb-5 flex-1">
                    <div class="shadow-lg bg-white rounded-lg ml-3 pb-5 relative p-5">
                        <VueForm
                            vModel={this.templateForm}
                            class="text-red-500"
                            schema={this.templateSchema}
                            scopedSlots={scopedSlots}
                        ></VueForm>
                        <VueForm
                            vModel={this.datasForm}
                            class="text-red-500"
                            schema={this.datasSchema}
                            ui-schema={this.dataUiSchema}
                            custom-rule={this.customRule}
                            scopedSlots={scopedSlots}
                        ></VueForm>
                    </div>
                </div>
            </div>
        );
    },
};
